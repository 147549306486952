#root {
  height: 100vh;
}

.header-notification {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;
  }

  & > .notification-bell {
    font-size: 20px;
    cursor: pointer;
  }
}

.gx-sidebar-content {
  & > .ant-menu {
    & > .ant-menu-item {
      margin: 12px 12px;

      & > .menu-icons {
        font-size: 20px;
      }

      & > span {
        font-size: @font-size-base;
        font-weight: @font-weight-normal;
      }
    }

    & > .ant-menu-item-selected {
      background-color: @primary-color;
      color: @white-color;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
      height: 50px;
    }
  }
}
.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 0;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password {
  text-align: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
}

.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.pointer {
  cursor: pointer;
}

.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: #efefef;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

.content-card {
  border: none;
  border-radius: 10px;
  margin-left: 17px;

  .ant-card-body {
    padding: 9px 0;
  }
}
.main-wrapper {
  min-width: 585px;
  max-width: 70%;
  margin: auto;
  padding: 28px;
  @media screen and (max-width: @screen-lg-max) {
    min-width: 100%;
  }
}
//CUSTOM STYLE CLASSES START

//margin & padding style
.m-0 {
  margin: 0;
}
.mt-4 {
  margin-top: 4px;
}
.mt-10 {
  margin-top: 10px;
}

.mt-8 {
  margin-top: 8px;
}
.mt-6 {
  margin-top: 6px;
}
.mt-20 {
  margin-top: 20px;
}
.mr-5 {
  margin-right: 5px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-16 {
  margin-left: 16px;
}

.mlr-5 {
  margin: 0 5px;
}
.mb-17 {
  margin-bottom: 17px;
}

.mb-6 {
  margin-bottom: 6px;
}

.m-auto {
  margin: auto;
}

.p-0 {
  padding: 0;
}

.pr-0 {
  padding-right: 10;
}

.pl-30 {
  padding-left: 30px;
}
.pl-15 {
  padding-left: 15px;
}

.pb-10 {
  padding-bottom: 10px;
}

//margin & padding style

// flex-box related style
.d-flex {
  display: flex;
}

.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-between {
  align-content: space-between;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}
// flex-box related style

//width classes
.width-25 {
  width: 25px;
}
.width-percent-20 {
  width: 20%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.line-24 {
  line-height: 24px;
}

.fill-width {
  width: 100%;
}

//width classes

//height classes

.card-height {
  min-height: calc(100vh - 40px);
  height: fit-content;
  overflow-y: auto;
  @media screen and (max-width: @screen-lg) {
    overflow-y: unset;
  }
}

.height-percent-35 {
  height: 35%;
}
//height classes

//cursor
.pointer {
  cursor: pointer;
}
//cursor

//border style
.b-0 {
  border: none;
}
//border style

.font-xx-large {
  font-size: xx-large;
}

.font-large {
  font-size: large;
}

.font-12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
}
.font-12-light {
  font-size: 12px;
  font-weight: 300;
  line-height: 100%;
}
.font-10 {
  font-size: 10px;
  font-weight: 300;
  line-height: 150%;
}

.font-10-bold {
  font-size: 10px;
  font-weight: 500;
  line-height: 100%;
}
.font-11 {
  font-size: 11px;
  font-weight: 400;
  line-height: 150%;
}
.font-16 {
  font-size: 16px;
  font-weight: 280;
  line-height: 100%;
}

.font-red {
  color: @red-font;
}

.font-active {
  color: @active-color;
}

.font-primary {
  color: @primary-color;
}
.visibility-hidden {
  visibility: hidden;
}
.text-align-center {
  text-align: center;
}
.table-heading {
  font-size: 16px;
  font-weight: 300;
  line-height: 100%;
  color: @primary-color;
}

//CUSTOM STYLE CLASSES END

.layout-padding {
  padding: 20px 20px 20px 0px;
}

.middle-layout {
  width: 305px;
  overflow-y: auto;

  @media screen and (max-width: @screen-lg) {
    overflow-y: unset;
  }
  .ant-card-head-title {
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    color: @black-color;
  }

  .ant-card-head {
    padding: 0 15px;
  }

  .ant-card-body {
    padding: 0 15px 17px 15px;
  }
}

.middle-layout-margin {
  margin-left: 28px;
  @media screen and (max-width: @screen-lg) {
    margin-bottom: 25px;
    margin-left: 17px;
  }
}

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  margin-top: 18px;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: unset;
}

.ant-btn {
  border-radius: 4px;
  font-size: 9px;
  line-height: 100%;
  font-weight: 500;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 77px;
}
.ant-btn > span {
  margin-top: 1px;
}

.ant-btn-ghost {
  border-color: @primary-color;
  color: @primary-color;
}

.button-outline-red {
  border-color: @color-red;
  color: @color-red;
  &:hover,
  &:focus {
    border-color: @color-red;
    color: @color-red;
  }
}

.common-button-red {
  border-radius: 4px;
  background-color: @red-background;
  font-size: 9px;
  line-height: 100%;
  font-weight: 500;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 77px;
}

.available-credits-card {
  .ant-btn-danger {
    background-color: @color-red;
    font-size: 9px;
    line-height: 100%;
    font-weight: 500;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 77px;
  }
}
.responsive-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: @screen-lg) {
    flex-direction: column;
    overflow-y: auto;
  }
}

.ant-card-bordered {
  border: unset;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  .ant-card-head {
    border-bottom: unset;
  }
}

.ant-result-icon {
  display: none;
}

.ant-result {
  padding: 48px 32px 16px 32px;
}

.ant-modal-header {
  display: flex;
  justify-content: center;
  border-radius: 16px;
  border: none;
  .ant-modal-title {
    font-size: 13px;
    font-weight: 300;
    color: @primary-color;
    padding-top: 8px;
  }
}

.form-footer {
  display: flex;
  justify-content: center;
  margin: 25px 0 40px 0;
  .ant-form-item {
    margin-bottom: 30px;
  }
}

.modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 22px;
  padding-bottom: 30px;
}

.ant-modal-content {
  width: 355px;
  border-radius: 16px;
}

.ant-modal-body {
  padding: 0 24px;
}

.ant-checkbox-inner {
  border-radius: unset;
  border: 1px solid black;
  border-radius: 2px;
}

.ant-checkbox-inner {
  height: 12px;
  width: 12px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @checkbox-background;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 1px solid @primary-color;
  border-top: 0;
  border-left: 0;
}

.ant-checkbox-inner::after {
  width: 4px;
  height: 7px;
  top: 40%;
}

.table-header-main {
  margin: 36px 0 23px 0;
  display: flex;
  justify-content: space-between;
}

.assigned-products-list {
  padding-left: 10px;
}
.ant-radio-inner {
  height: 12px;
  width: 12px;
}
::-webkit-scrollbar {
  width: 2px;
}

:hover::-webkit-scrollbar-thumb {
  background: @grey-color;
}

:focus::-webkit-scrollbar-thumb {
  background: @grey-color;
}
.ant-steps-item-title:after {
  background-color: red !important;
}

//Commenting for future reference

// .stepper-main
//   .ant-steps-item
//   > .ant-steps-item-container
//   > .ant-steps-item-tail::after {
//   height: 10px;
// }
/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: @grey-color;
// }
//  &:not(:first-child):before {
// ::-webkit-scrollbar-thumb {
//   background: transparent;
// }

// .ant-card-extra {
//   padding-left: 7px;
// }
// .ant-upload-list-item {
//   margin-right: -138px;
// }

// .ant-upload-list-text .ant-upload-span {
//   margin-left: 11px;
// }
// .ant-upload-list-text .ant-upload-list-item-card-actions {
//   margin-top: -20px;
//   margin-left: -20px;
// }

// .ant-upload-list-item-card-actions .anticon {
//   margin-left: -154px;
// }
// .ant-table-summary {
//   display: table-header-group;
// }
.ant-modal-content {
  margin: auto;
}

.credits-width {
  min-width: 30px;
  max-width: 50%;
}

.credits-text {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}
