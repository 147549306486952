@import url(../../../styles/variables.less);

.upload-file-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: @primary-color;
  font-size: 13px;
  font-weight: 300;
  line-height: 100%;

  .upload-file-margin {
    margin-top: 65px;
  }
  .upload-button-margin {
    .ant-btn {
      margin-top: 20px;
    }
  }
  .ant-upload-list-text .ant-upload-list-item-card-actions {
    display: none;
  }
  .actions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .upload-button {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .ant-btn {
      width: 147px;
    }
    .ant-btn-link {
      margin-top: 5px;
      color: @black-color;
      font-size: 8px;
      font-weight: 300;
    }
    .ant-progress-outer {
      display: none;
    }
  }
}
