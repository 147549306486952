@import url(./../../../styles/global/variables.less);
@import url(../../../styles/variables.less);
.server-sync-table-main {
  // height: calc(100vh - 40px);
  .ant-spin-nested-loading {
    position: unset;
  }
  tr:last-child {
    td {
      border: none;
    }
  }
  .column-light {
    td {
      font-weight: 400;
    }
  }
  .ant-collapse {
    border: unset;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    height: 30px;
    background-color: @primary-color;
    color: @white-color;
    font-weight: 400;
    font-size: 11px;
    line-height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-radius: 0;
  }

  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: unset;
  }
  .synclog-table {
    .ant-spin-nested-loading {
      position: relative;
    }
  }
}
