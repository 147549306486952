@import url(../../src/styles/variables.less);
@import url(../styles/global/variables.less);
.stepper-main {
  .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    height: 2px;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: @active-color;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    border-color: @active-color;
  }

  .title-padding {
    .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      padding-left: 15px;
    }
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    margin-top: 4px;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: @active-color;
    border-color: @active-color;
    .ant-steps-icon {
      color: @white-color;
    }
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: @active-color;
  }

  .ant-steps-label-vertical .ant-steps-item-title {
    text-align: center;
    font-size: 10px;
    line-height: 100%;
    font-weight: 400;
  }

  .ant-steps-label-vertical .ant-steps-item-finish .ant-steps-item-tail {
    margin-left: 63px;
    padding: 0;
    margin-top: 3.7px;
    ::after {
      margin-left: -2px;
    }
  }

  .ant-steps-label-vertical .ant-steps-item-tail {
    padding: 0;
    margin-top: 3.7px;
  }
  .ant-steps-item-tail::after {
    width: 100%;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: @active-color;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: @grey-color;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: @black-color;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: @grey-color;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: @grey-color;
  }

  .ant-steps-label-vertical .ant-steps-item-content {
    display: block;
    width: unset;
    text-align: start;
  }

  .ant-steps-label-vertical .ant-steps-item-content {
    margin-top: unset;
  }
  .step-icons {
    height: 24px;
    width: 17px;
    background: white;
  }
  .progress-icons {
    height: 22px;
    width: 24px;
    margin-top: -3px;
  }
}
