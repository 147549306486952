@import url('../styles//global/variables.less');

.common-table-wrapper {
  .anticon {
    padding: 0 30px;
  }
  .ant-table {
    table {
      width: unset;
      min-width: calc(100% - 5px);
    }
    & .ant-table-tbody {
      font-size: 10px;
      font-weight: 300;
      & .anticon {
        font-size: 20px;
        padding: 0 4px;
      }
    }
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    font-size: 10px;
    padding: 0;
  }
  .ant-table {
    .ant-radio-wrapper {
      margin-right: unset;
      margin-left: 18px;
    }
    & .ant-table-tbody {
      & .ant-btn {
        margin: 0;
        padding: 0;
        height: auto;
      }
    }

    & .ant-table-tbody > tr > td {
      height: 26px;
      padding: unset;
    }
  }
  .ant-pagination-prev .ant-pagination-item-link {
    border: none;
  }

  .ant-pagination-next .ant-pagination-item-link {
    border: none;
  }

  .ant-pagination-prev {
    border: none;
  }
  .ant-pagination-item {
    border: unset;
  }
  .ant-table-thead > tr > th {
    color: @table-heading-color;
    background: @white-color;
    font-size: 10px;
    font-weight: 400;
    height: 5px;
  }

  .ant-table-selection {
    padding-bottom: 5px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px;
  }
  td:first-child {
    font-weight: 500;
  }
  tr:last-child {
    td {
      border-color: @primary-color;
    }
  }
  .ant-table-summary {
    height: 26px;
    tr:last-child {
      td {
        border-color: @grey-4;
      }
    }
  }
}
