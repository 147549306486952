@import url(./../../../styles/global/variables.less);
@import url(../../../styles/variables.less);

.connect-server-main {
  .ant-card-head {
    min-height: 28px;
  }
  .heading-blue {
    .ant-card-head-title {
      padding: 16px 0 0 0;
      color: @primary-color;
    }
  }
  .ant-card-extra {
    padding: 16px 0 0 0;
  }
  .ant-form-item {
    margin-bottom: unset;
  }
  .ant-form-item-control-input {
    min-height: unset;
    margin-bottom: 6px;
  }

  .connect-server-password {
    .ant-input {
      bottom: 5px;
      height: 13px;
      border-radius: unset;
    }
  }
  .ant-input-password {
    border: 0.5px solid @primary-color;
    padding-top: 9px;
    border-radius: 4px;
    height: 22px;
    background: @input-backgound;
    font-weight: 300;
    font-size: 9px;
  }
  .ant-input {
    border: 0.5px solid @primary-color;
    padding-top: 9px;
    border-radius: 4px;
    height: 22px;
    background: @input-backgound;
    font-weight: 300;
    font-size: 9px;
  }
  .ant-btn {
    min-width: 110px;
  }
  .ant-input:placeholder-shown {
    font-weight: 300;
    font-size: 9px;
    line-height: 100%;
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 6px 0 0 0;
  }
  .ant-form-vertical .ant-form-item-label > label {
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
  }
  .ant-checkbox-inner {
    height: 12px;
    width: 12px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @white-color;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 1px solid @primary-color;
    border-top: 0;
    border-left: 0;
  }

  .ant-checkbox-inner::after {
    width: 4px;
    height: 7px;
    top: 40%;
  }
  .ant-checkbox + span {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    font-size: 9px;
  }

  .auto-sync-active {
    background-color: @active-color;
    color: @white-color;
    font-size: 9px;
    font-weight: 400;
    line-height: 150%;
    padding: 1px 5px 0 5px;
    border-radius: 4px;
  }
}
