@import url(./../../../styles/global/variables.less);
@import url(../../../styles/variables.less);

.settings-card-main {
  .ant-divider-horizontal {
    margin: 11px 0;
  }
  .ant-layout .ant-card-bordered .ant-card-body {
    font-weight: 300;
    font-size: 12px;
    line-height: 100%;
  }
  .heading-blue {
    .ant-card-head-title {
      color: @primary-color;
    }
  }
  .settings-text {
    font-weight: 300;
    font-size: 12px;
    line-height: 100%;
  }
  .ant-switch {
    background-color: @grey-10;
  }

  .ant-switch-checked {
    background-color: @active-color;
  }
}
