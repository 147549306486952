@import url(../../src/styles/variables.less);
@import url(../../src/styles/global/variables.less);

.search-component-main {
  padding: 8px;
  .ant-input {
    display: block;
    border: 0.5px solid @primary-color;
    padding-top: 9px;
    border-radius: 4px;
    height: 22px;
    background: @input-backgound;
    font-weight: 300;
    font-size: 9px;
    margin-bottom: 8px;
  }
  .ant-space {
    display: flex;
    justify-content: space-between;
  }
  .ant-btn {
    min-width: 90px;
  }
}
