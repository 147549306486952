@import url(../../../styles/variables.less);
.sync-successful-main {
  margin: 75px;
  .sync-heading {
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
    color: @primary-color;
    margin-bottom: 25px;
  }
  .success-icon {
    margin-bottom: 28px;
  }
}
