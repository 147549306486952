@import url(../../src/styles/variables.less);
@import url(../styles/global/variables.less);
.pagination-main {
  .ant-pagination {
    display: flex;
    flex-wrap: nowrap;
  }
  .ant-pagination.mini .ant-pagination-item {
    border: none;
    font-weight: 300;
    font-size: 9px;
    min-width: 10px;
    padding-top: 3px;
  }
  .ant-pagination.mini .ant-pagination-item-active {
    font-weight: 700;
  }
  .anticon svg {
    color: @primary-color;
    height: 9px;
    width: 9px;
  }
  .ant-pagination-item a {
    color: @primary-color;
    padding: 0 1.5;
  }
}
