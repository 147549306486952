@import url(../../src/styles/variables.less);
@import url(../../src/styles/global/variables.less);

.new-configuration-main {
  .ant-radio {
    top: 1px;
  }
  .ant-checkbox {
    top: 1.5px;
  }
  .ant-radio-checked .ant-radio-inner {
    background-color: @primary-color;
  }

  .new-configuration-main span.ant-checkbox + * {
    margin-bottom: -5px;
  }
  .ant-radio-inner {
    border: 1px solid @black-color;
    border-radius: 2px;
    height: 9px;
    width: 9px;
  }
  .ant-radio-inner::after {
    position: absolute;
    top: 11px;
    left: 9px;
    display: table;
    width: 3px;
    height: 6px;
    background-color: @checkbox-background;
    border: 1px solid @white-color;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' ';
  }
  .ant-radio-checked .ant-radio-inner {
    background-color: @checkbox-background;
  }
  .ant-radio-checked .ant-radio-inner::after {
    position: absolute;
    display: table;
    background-color: @radio-background;
    border: 1px solid @primary-color;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    border-radius: unset;
    content: '';
  }

  span.ant-radio + * {
    font-size: 9px;
    font-weight: 300px;
    line-height: 100%;
  }

  span.ant-checkbox + * {
    font-size: 9px;
    font-weight: 300px;
    line-height: 100%;
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-inner {
    height: 9px;
    width: 9px;
  }

  .ant-checkbox-inner::after {
    width: 3px;
    height: 6px;
    top: 40%;
    left: 1px;
  }

  .ant-tree-switcher {
    display: none;
  }
  .ant-tree-checkbox {
    display: none;
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: 'unset';
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
}
