@import url(./../../../styles/global/variables.less);
@import url(../../../styles/variables.less);
.available-credits-main {
  .credits-content {
    padding-left: 10px;
    font-size: 11px;
    font-weight: 300;
    line-height: 100%;
    height: 20px;
  }
  .ant-row {
    height: 20px;
  }

  .credits-width {
    min-width: 30px;
    max-width: 50%;
  }
  .credits-text {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }
}
