@import url(../../../styles/global/variables.less);
@import url(../../../styles/variables.less);

.file-modal-wrapper {
  .ant-table-thead > tr > th {
    font-size: 7px;
    font-weight: 700;
    color: @black-color;
  }
  td:first-child {
    font-weight: 275;
  }
  tr:last-child {
    td {
      border-color: @grey-4;
    }
  }
}
